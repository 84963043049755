import { generalConfig } from './generalConfig';

const url = {
  protocol: 'https://',
  url: 'test.backend.dashboard.talento.mobydigital.com',
};

export const environment = {
  url,
  ...generalConfig,
  production: false,
};
